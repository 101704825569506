import React from 'react'

export const LogoIcon = () => (
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 262 307"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <rect id="path-1" x="122" y="61" width="256" height="256" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-119.000000, -57.000000)">
        <g id="Rectangle">
          <use fill="#850000" fillRule="evenodd" />
          <rect
            fill="#850000"
            strokeWidth="1"
            x="122.5"
            y="61.5"
            width="255"
            height="255"
          />
        </g>
        <text
          id="R"
          fontFamily="Fira Sans"
          fontSize="120"
          fontWeight="400"
          fill="#FFFFFF"
        >
          <tspan x="142" y="174">
            R
          </tspan>
        </text>
        <text
          id="RED-CORNER"
          fontFamily="Fira Sans"
          fontSize="40"
          fontWeight="400"
          fill="#850000"
        >
          <tspan x="135" y="362">
            RED CORNER
          </tspan>
        </text>
        <text
          id="C"
          fontFamily="Fira Sans"
          fontSize="120"
          fontWeight="400"
          fill="#FFFFFF"
        >
          <tspan x="273.458984" y="286">
            C
          </tspan>
        </text>
        <path
          d="M122.5,315.5 L378,60"
          id="Line"
          stroke="#FFFFFF"
          strokeWidth="4"
          strokeLinecap="square"
        />
      </g>
    </g>
  </svg>
)
