import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { LogoIcon } from "../components/logo"

const IndexPage = () => (
  <Layout>
    <Seo />
    <LogoIcon />
    <div className="contact">
      <div>office ( at ) redcorner dot at</div>
      <div>Dornbirn | Austria</div>
    </div>
  </Layout>
)

export default IndexPage
